import { disableActions, enableActions } from '@/api/pmactions';

const disableAction = {
	id: 'disableAction',
	selectionType: 'multiple',
	label: 'pmactions.actions.disableAction',
	functionality: 'UPDATE_PMACTIONS',
	checkAvailability: function (action) {
		return action && action.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmactions.actions.disableAction');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmactionsid: registries[i].pmactionsid
			});
		}

		const data = await disableActions(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableAction = {
	id: 'enableAction',
	selectionType: 'multiple',
	label: 'pmactions.actions.enableAction',
	functionality: 'UPDATE_PMACTIONS',
	checkAvailability: function (action) {
		return action && action.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmactions.actions.enableAction');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmactionsid: registries[i].pmactionsid
			});
		}

		const data = await enableActions(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableAction, enableAction]
};
