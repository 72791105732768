// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableActions(action) {
	const controller = '/pmactions/disableActions';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		action,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableActions(action) {
	const controller = '/pmactions/enableActions';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		action,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
				console.log(error);
			}
		}
	);
	return data;
}
