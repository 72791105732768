<template>
	<div>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:actions="actions"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import ActionsActions from './PmActionsActions';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmActionsGrid',
	data() {
		return {
			model: 'pmactions',
			actions: ActionsActions.actions,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			externalFilter: null
		};
	},
	computed: {},
	mounted() {
		if (!isSuperAdmin(this.session.profiles[0])) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},

	beforeDestroy() {
		console.log('BEFORE DESTROY');
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
